<template>
  <div class="playlist">
    <div class="new-campaign">
      <div class="container">
        <div class="header-wrap">
          <h2>{{ $t("ads.newTitle") }}</h2>
          <h3 style="float: none; max-width: 800px; text-align: center; margin: 0px auto">
            {{ $t("ads.description") }}
          </h3>
          <br /><br />
        </div>

        <div class="creation-wrap adu">
          <form class="form">
            <h3 style="color: #444; font-weight: 600; margin: 0; font-size: 20px">{{ $t("ads.upload") }}</h3>
            <div class="row row-gutter-20">
              <div class="col-16">
                <div class="ad-upload-wrap" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                  <input type="file" @change="onChange" ref="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" style="display: none" />

                  <label for="assetsFieldHandle" class="cursor-pointer">
                    <h4>{{ $t("ads.available") }}<br />{{ $t("ads.uploadNow") }}</h4>
                    <p class="icon">
                      <span class="material-icons">backup</span>
                    </p>
                    <div>{{ $t("ads.uploadInfo") }}</div>
                  </label>

                  <ul class="file-list" v-if="this.filelist.length" v-cloak>
                    <li v-for="file in filelist" v-bind:key="file.key">
                      {{ file.name }}
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-8 desktop-only" v-if="this.$theme === 'oohlemon'">
                <a href="https://www.canva.com" target="_blank" class="canva-wrap">
                  <img src="../../assets/images/canva.png" />
                  <p>{{ $t("ads.canva") }}</p>
                </a>
              </div>

              <!-- // Canva Integration
              <div class="col-12 desktop-only" v-if="this.$theme === 'oohlemon'">
                <a @click="load_canva('horizontal')" target="_blank" class="canva-wrap">
                  <div style="width: 50px; display: inline-block; vertical-align: middle; margin-right: 20px">
                    <p style="margin: 0">
                      <img src="../../assets/images/canva.png" style="width: 50px; height: auto" />
                    </p>
                  </div>
                  <div style="width: calc(100% - 70px); display: inline-block; vertical-align: middle">
                    <p style="font-size: 16px; font-weight: 600; margin: 0">
                      <span style="display: inline-block; vertical-align: middle">{{ $t("ads.vertical") }}</span
                      ><span class="material-icons" style="margin-left: 10px; display: inline-block; vertical-align: middle">panorama_horizontal</span>
                    </p>
                    <p style="font-size: 12px; margin: 5px 0 0 0">{{ $t("ads.editor") }}</p>
                  </div>
                </a>
                <a @click="load_canva('vertical')" target="_blank" class="canva-wrap">
                  <div style="width: 50px; display: inline-block; vertical-align: middle; margin-right: 20px">
                    <img src="../../assets/images/canva.png" style="width: 50px; height: auto" />
                  </div>
                  <div style="width: calc(100% - 70px); display: inline-block; vertical-align: middle">
                    <p style="font-size: 16px; font-weight: 600; margin: 0">
                      <span style="display: inline-block; vertical-align: middle">{{ $t("ads.horizontal") }}</span
                      ><span class="material-icons" style="margin-left: 10px; display: inline-block; vertical-align: middle">panorama_vertical</span>
                    </p>
                    <p style="font-size: 12px; margin: 5px 0 0 0">{{ $t("ads.editor") }}</p>
                  </div>
                </a>
              </div>
              -->
            </div>

            <img class="preview-image" :src="filePreview" v-if="filePreview" />

            <div class="settings fifthy">
              <p>
                <strong>{{ $t("ads.allowed") }}:</strong><br />
                <em>{{ $t("global.image") }}:</em> PNG {{ $t("global.and") }} JPG<br />
                <em>{{ $t("global.video") }}:</em> MP4
              </p>
              <p>
                <strong>{{ $t("ads.settings") }}:</strong><br />
                <em>{{ $t("global.ratio") }}:</em> 16:9 {{ $t("global.and") }} 9:16<br />
                <em>{{ $t("global.duration") }}:</em> 15-30 {{ $t("global.seconds") }}
              </p>
            </div>
            <p style="margin-top: 0">{{ $t("ads.editing") }}</p>
            <br />
            <h3 style="color: #444; font-weight: 600; margin: 0; font-size: 20px">{{ $t("ads.mediaInfo") }}</h3>
            <br />
            <div class="form-wrap">
              <label for="name"
                >{{ $t("global.name") }} <small>({{ $t("global.mandatory") }})</small></label
              >
              <input v-model="advertisment.name" class="form-input" type="name" name="name" id="name" />
            </div>

            <div class="form-wrap select-wrap">
              <label for="street_number"
                >{{ $t("ads.type") }} <small>({{ $t("global.mandatory") }})</small></label
              >
              <select v-model="advertisment.advertisment_type" class="select-wrap">
                <option value="image">{{ $t("global.image") }}</option>
                <option value="video">{{ $t("global.video") }}</option>
              </select>
            </div>

            <div v-if="advertisment.advertisment_type == 'image'" class="form-wrap">
              <label for="duration"
                >{{ $t("global.duration") }} (in {{ $t("global.seconds") }}) <small>({{ $t("global.mandatory") }})</small></label
              >
              <input v-model="advertisment.duration" class="form-input" type="duration" name="duration" id="duration" />
            </div>

            <div v-if="progress_bar" style="margin-bottom: 20px">
              <p style="display: block; font-size: 16px; margin-bottom: 8px; font-weight: 600; color: #91979f">{{ $t("ads.progress") }}:</p>

              <div class="progress-bar">
                <span class="progress" :style="'width:' + progress + '%'"></span>
                <p>{{ progress }} %</p>
              </div>
            </div>

            <p style="margin-bottom: 0; text-align: right">
              <a @click="create_advertisment" class="button button-ci button-round button-100" style="cursor: pointer">{{ $t("global.createNow") }}</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from 'axios'

export default {
  name: "new_playlist",
  data() {
    return {
      errors: [],
      loading: true,
      advertisment: {
        name: "",
        advertisment_type: "",
        asset_file: null,
        duration: 15,
      },
      progress_bar: false,
      progress: 0,
      canva_api: null,
      filelist: [],
      filePreview: "",
    };
  },
  methods: {
    create_advertisment: function () {
      var that = this;

      if (this.advertisment.asset_file == null) {
        this.$notify({
          group: "notification",
          type: "error",
          text: this.$t("notification.fileError"),
        });
      } else {
        var form_data = new FormData();
        form_data.append("asset_file", this.advertisment.asset_file);
        form_data.append("name", this.advertisment.name);
        form_data.append("advertisment_type", this.advertisment.advertisment_type);
        form_data.append("duration", this.advertisment.duration);

        this.$http
          .post(process.env.VUE_APP_BASE_API + "/advertisments", form_data, {
            headers: { Authorization: that.$store.getters.getToken },
            progress(e) {
              that.progress_bar = true;
              if (e.lengthComputable) {
                that.progress = parseFloat((e.loaded / e.total) * 100).toFixed(2);
              }
            },
          })
          .then(
            (response) => {
              if (this.$route.query.uuid) {
                this.$router.push("/campaigns/" + this.$route.query.uuid);
              } else if (this.$route.fullPath.includes("playlists")) {
                var pathes = this.$route.fullPath.split("/");
                this.$router.push("/playlists/" + pathes[2]);
              } else {
                this.$router.push("/advertisments");
              }
              console.log(response);
            },
            (response) => {
              response.body.errors.forEach((value) => {
                this.$notify({
                  group: "notification",
                  type: "error",
                  text: value,
                });
              });
            }
          );
      }
    },
    previewFile(event) {
      this.advertisment.asset_file = event.target.files[0];
    },
    async load_canva(format) {
      var design_type = "";
      if (format == "horizontal") {
        design_type = "YouTubeThumbnail";
      } else if (format == "vertical") {
        design_type = "InstagramStory";
      }

      if (window.Canva && window.Canva.DesignButton) {
        if (!this.canva_api) {
          this.canva_api = await window.Canva.DesignButton.initialize({
            apiKey: "h5diJ3Bh_FFklu2PsFWOvmBK",
          });
        }
        this.canva_api.createDesign({
          design: {
            type: design_type,
          },
          onDesignOpen: ({ designId }) => {
            console.log(designId);
          },
          onDesignPublish: ({ exportUrl, designId }) => {
            fetch(exportUrl)
              .then((response) => response.blob())
              .then((blob) => {
                var file = new File([blob], designId + ".png");
                this.advertisment.asset_file = file;
                this.advertisment.name = "Canva - " + designId;
                this.advertisment.advertisment_type = "image";
              });
          },
          onDesignClose: () => {},
        });
      }
    },

    onChange() {
      let dropFiles = [...this.$refs.file.files];
      this.filelist = dropFiles.splice(1);
      this.filelist = dropFiles;
      this.advertisment.asset_file = this.filelist[0];
      this.advertisment.name = this.filelist[0].name;

      if (this.filelist[0].type === "image/png" || this.filelist[0].type === "image/jpeg") {
        this.advertisment.advertisment_type = "image";

        const file = this.filelist[0];
        this.filePreview = URL.createObjectURL(file);
      } else if (this.filelist[0].type === "video/mp4") {
        this.advertisment.advertisment_type = "video";
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green")) {
        event.currentTarget.classList.remove("bg-gray");
        event.currentTarget.classList.add("bg-green");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray");
      event.currentTarget.classList.remove("bg-green");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray");
      event.currentTarget.classList.remove("bg-green");
    },
  },
  mounted() {
    (function (document, url) {
      var script = document.createElement("script");
      script.src = url;
      script.onload = function () {};
      document.body.appendChild(script);
    })(document, "https://sdk.canva.com/designbutton/v2/api.js");
  },
};
</script>

<style lang="scss">
.ad-upload-wrap {
  margin-top: 20px;
  background: #f9f9f9;
  border: 3px solid #f1f1f1;
  padding: 25px;
  border-radius: 5px;
  min-height: 245px;
  text-align: center;

  h4 {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 600;
  }

  p.icon {
    margin: 20px 0;
    span {
      font-size: 40px;
    }
  }

  input[type="file"] {
    margin-top: 25px;
  }
}

.canva-design-button {
  width: 405px;
}

iframe:not(.md-image) {
  height: calc(100vh - 100px) !important;
}

.file-list {
  margin-bottom: 0 !important;
  li {
    font-size: 16px !important;
    float: none !important;
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .fifthy {
    display: flex;

    p {
      margin-right: 2rem;
    }
  }
}

.settings {
  margin-top: 1rem;

  em {
    font-style: normal;
    font-weight: 600;
  }

  strong {
    margin-bottom: 5px;
    display: inline-block;
  }
}

.preview-image {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
